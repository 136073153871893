<template>
  <div id="page-user-view">
    <vs-tabs
      :position="isSmallerScreen ? 'top' : 'left'"
      class="tabs-shadow-none"
      id="profile-tabs"
      :key="isSmallerScreen"
      :value="activeTab"
      v-model="activeTab"
      @input="setActiveTab"
    >
      <!-- GENERAL -->
      <vs-tab
        icon-pack="feather"
        icon="icon-user"
        :label="!isSmallerScreen ? $t('General') : ''"
      >
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card :title="$t('Account')">
            <div class="lg:w-1/3">
              <vs-row vs-type="flex" vs-justify="center" vs-align="center">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="12"
                >
                  <table>
                    <tr>
                      <td class="font-semibold">{{ $t("Company") }}</td>
                      <td>{{ getCompany }}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">{{ $t("Name") }}</td>
                      <td v-if="!editName">{{ getUserName }}</td>
                      <td v-else>
                        <vs-input
                          type="text"
                          v-model="userName"
                          class="w-full mt-0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="font-semibold">{{ $t("Email") }}</td>
                      <td>{{ getEmail }}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold">{{ $t("Language") }}</td>
                      <td>
                        <vs-dropdown
                          :style="
                            !editName
                              ? ''
                              : 'border: 1px solid rgba(0, 0, 0, 0.2); padding: .7rem !important;font-size: 1rem !important;border-radius: 5px;'
                          "
                          :disabled="!editName"
                          vs-custom-content
                          vs-trigger-click
                          class="cursor-pointer"
                        >
                          <span
                            class="cursor-pointer flex items-center i18n-locale"
                          >
                            <img
                              class="h-4 w-5"
                              :src="i18n_locale_img"
                              :alt="locale"
                            />
                            <span class="hidden sm:block ml-2">{{
                              locale_lang
                            }}</span>
                          </span>
                          <vs-dropdown-menu
                            class="w-48 i18n-dropdown vx-navbar-dropdown"
                          >
                            <vs-dropdown-item
                              @click="
                                locale = 'en';
                                locale_lang = 'English';
                              "
                              ><img
                                class="h-4 w-5 mr-1"
                                src="@/assets/images/flags/en.png"
                                alt="en"
                              />
                              &nbsp;English</vs-dropdown-item
                            >
                            <vs-dropdown-item
                              @click="
                                locale = 'tr';
                                locale_lang = 'Türkçe';
                              "
                              ><img
                                class="h-4 w-5 mr-1"
                                src="@/assets/images/flags/tr.png"
                                alt="fr"
                              />
                              &nbsp;Türkçe</vs-dropdown-item
                            >
                          </vs-dropdown-menu>
                        </vs-dropdown>
                      </td>
                    </tr>
                    <tr v-if="getDesignMarketPermission">
                      <td class="font-semibold">{{ $t("Credit") }}</td>
                      <td>{{ getDesignMarketCredit }}</td>
                    </tr>
                    <tr v-if="getDentalPermission">
                      <td class="font-semibold">{{ $t("Credit") }}</td>
                      <td>{{ getDentalCredit }}</td>
                    </tr>
                  </table>
                </vs-col>
              </vs-row>
              <vs-row vs-type="flex" vs-justify="flex-end">
                <vs-button
                  v-if="!editName"
                  type="border"
                  @click="editName = true"
                  >{{ $t("Edit") }}</vs-button
                >
                <vs-button
                  v-else
                  @click="
                    changeUserName();
                    editName = !editName;
                  "
                  >{{ $t("Update") }}</vs-button
                >
              </vs-row>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <vs-tab
        icon-pack="feather"
        icon="icon-lock"
        :label="!isSmallerScreen ? $t('Change_Password') : ''"
      >
        <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card title="Password Reset">
            <div class="p-1 lg:w-1/3">
              <div class="vx-card__title mb-8">
                <p>{{ $t("Password_Message_1") }}</p>
              </div>
              <vs-input
                @input="oldPassValidate = false"
                :danger="oldPassValidate"
                :danger-text="errorMessage"
                type="password"
                v-model="oldPass"
                :label-placeholder="$t('Old_Password')"
                class="w-full mb-6"
              />
              <vs-input
                @input="newPassValidate = false"
                :danger="newPassValidate"
                :danger-text="errorMessage"
                type="password"
                v-model="newPass"
                :label-placeholder="$t('New_Password')"
                class="w-full mb-6"
              />
              <vs-input
                @input="confirmPassValidate = false"
                :danger="confirmPassValidate"
                :danger-text="errorMessage"
                type="password"
                v-model="confirmNewPass"
                :label-placeholder="$t('Confirm_New_Password')"
                class="w-full mb-8"
              />

              <div
                class="flex flex-wrap justify-end flex-col-reverse sm:flex-row"
              >
                <vs-button
                  type="border"
                  class="w-full sm:w-auto"
                  @click="resetPasswordRequest()"
                  >{{ $t("Reset") }}</vs-button
                >
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <vs-tab
        icon-pack="feather"
        icon="icon-info"
        :label="!isSmallerScreen ? $t('Company_Info') : ''"
      >
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card :title="$t('Company_Information')">
            <div class="lg:w-1/2">
              <vs-input
                @input="infoValidateActive = false"
                :disabled="!editableInfo"
                type="text"
                v-model="title"
                :label-placeholder="$t('Company') + '*'"
                class="w-full"
              />
              <vs-textarea
                @input="infoValidateActive = false"
                :disabled="!editableInfo"
                type="text"
                v-model="address"
                :label="$t('Address') + '*'"
                class="w-full mt-8"
              />

              <vs-input
                v-on:keypress="isLetter($event)"
                @input="infoValidateActive = false"
                :disabled="!editableInfo"
                type="text"
                v-model="vat_id"
                :label-placeholder="$t('Tax_Number') + '*'"
                class="w-full mt-8"
              />
              <div class="flex flex-wrap">
                <div class="md:pr-1 sm:pl-0 flex lg:w-1/2 w-full">
                  <vs-input
                    @input="infoValidateActive = false"
                    :disabled="!editableInfo"
                    type="text"
                    v-model="city"
                    :label-placeholder="$t('City') + '*'"
                    class="w-full mt-8"
                  />
                </div>
                <div class="md:pl-1 sm:pr-0 flex lg:w-1/2 w-full">
                  <v-select
                    @input="infoValidateActive = false"
                    :disabled="!editableInfo"
                    class="w-full mt-8"
                    :options="countries"
                    :placeholder="$t('Country') + '*'"
                    v-model="country"
                  ></v-select>
                </div>
                <div
                  class="md:pr-1 sm:pr-0 flex lg:w-1/2 w-full"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="12"
                >
                  <vs-input
                    v-on:keypress="isLetter($event)"
                    @input="infoValidateActive = false"
                    :disabled="!editableInfo"
                    type="number"
                    v-model="tel1"
                    :label-placeholder="$t('Phone') + ' 1*'"
                    class="w-full mt-8"
                  />
                </div>

                <div class="md:pl-1 sm:pl-0 flex lg:w-1/2 w-full">
                  <vs-input
                    v-on:keypress="isLetter($event)"
                    :disabled="!editableInfo"
                    type="number"
                    v-model="tel2"
                    :label-placeholder="$t('Phone') + ' 2'"
                    class="w-full mt-8"
                  />
                </div>
              </div>

              <vs-alert
                class="mt-4"
                :active="infoValidateActive"
                color="danger"
                icon="new_releases"
              >
                <span>{{ infoValidate }}</span>
              </vs-alert>

              <div class="flex justify-end">
                <vs-button
                  v-if="editableInfo"
                  class="mt-6"
                  :disabled="updateInfoButtonDisable"
                  @click="sendUpdateInfoRequest"
                  >{{ $t("Update") }}</vs-button
                >
                <vs-button
                  v-else
                  type="border"
                  class="mt-6"
                  :disabled="updateInfoButtonDisable"
                  @click="editableInfo = !editableInfo"
                  >{{ $t("Edit") }}</vs-button
                >
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";
import countryAndCity from "./coutryCity.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      locale_lang: "English",
      locale: "en",
      infoValidateActive: false,
      infoValidate: "",

      activeTab: 0,

      countries: countryAndCity,
      editName: false,
      userName: "",
      title: "",
      vat_id: "",
      address: "",
      city: "",
      country: "",
      tel1: "",
      tel2: "",
      updateInfoButtonDisable: false,
      editableInfo: false,

      oldPass: "",
      newPass: "",
      confirmNewPass: "",

      oldPassValidate: false,
      newPassValidate: false,
      confirmPassValidate: false,

      errorMessage: "",
    };
  },

  methods: {
    setLocaleLang() {
      this.locale_lang = this.getCurrentLocaleData.lang;
      this.locale = this.$i18n.locale;
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    setActiveTab() {
      if (this.activeTab != 0) {
        var params = {
          active_tab: this.activeTab,
        };

        this.$router.push({ query: params }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      } else {
        this.$router.push({}).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },
    handleChangeUserName(status, data) {
      if (status == 200) {
        this.$i18n.locale = data.language;
        this.$store.dispatch("setUserInfo", data);
        this.$vs.notify({
          title: this.$t("Successful"),
          text: this.$t("Profile_Message_1"),
          color: "success",
        });
      } else if (status == 400) {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Profile_Message_2"),
          color: "warning",
        });
      }
    },
    changeUserName() {
      var params = {
        name: this.userName,
        language: this.locale,
      };
      Api.put(API.BASEURL + API.ME, this.handleChangeUserName, params);
    },
    handleGetCompanyInfo(status, data) {
      if (status == 200) {
        this.title = data.title;
        this.vat_id = data.vat_id;
        this.address = data.address;
        this.city = data.city;
        this.country = data.country;
        this.tel1 = data.tel1;
        this.tel2 = data.tel2;
      }
    },
    getCompanyInfo() {
      Api.get(API.BASEURL + API.UPDATE_INFO, this.handleGetCompanyInfo);
    },
    handleSendUpdateInfoRequest(status, data) {
      this.updateInfoButtonDisable = false;
      if (status == 200) {
        this.editableInfo = !this.editableInfo;
        this.$vs.notify({
          title: this.$t("Successful"),
          text: this.$t("Profile_Message_1"),
          color: "success",
        });
      } else if (status == 400) {
        if (
          typeof data.data.title != "undefined" &&
          data.data.title[0] != null
        ) {
          this.infoValidateActive = true;
          this.infoValidate = this.$t("Company") + " - " + data.data.title[0];
        } else if (
          typeof data.data.address != "undefined" &&
          data.data.address[0] != null
        ) {
          this.infoValidateActive = true;
          this.infoValidate = this.$t("Address") + " - " + data.data.address[0];
        } else if (
          typeof data.data.vat_id != "undefined" &&
          data.data.vat_id[0] != null
        ) {
          this.infoValidateActive = true;
          this.infoValidate =
            this.$t("Tax_Number") + " - " + data.data.vat_id[0];
        } else if (
          typeof data.data.city != "undefined" &&
          data.data.city[0] != null
        ) {
          this.infoValidateActive = true;
          this.infoValidate = this.$t("City") + " - " + data.data.city[0];
        } else if (
          typeof data.data.country != "undefined" &&
          data.data.country[0] != null
        ) {
          this.infoValidateActive = true;
          this.infoValidate = this.$t("Country") + " - " + data.data.country[0];
        } else if (
          typeof data.data.tel1 != "undefined" &&
          data.data.tel1[0] != null
        ) {
          this.infoValidateActive = true;
          this.infoValidate = this.$t("Phone") + "1 - " + data.data.tel1[0];
        }

        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Profile_Message_3"),
          color: "warning",
        });
      }
    },
    sendUpdateInfoRequest() {
      if (this.vat_id.length < 6) {
        this.infoValidateActive = true;
        this.infoValidate = this.$t("Profile_Message_4");
        return;
      }

      this.updateInfoButtonDisable = true;
      if (this.country == null) {
        this.country = "";
      }
      var params = {
        title: this.title,
        vat_id: this.vat_id,
        address: this.address,
        city: this.city,
        country: this.country,
        tel1: this.tel1,
        tel2: this.tel2,
      };

      Api.put(
        API.BASEURL + API.UPDATE_INFO,
        this.handleSendUpdateInfoRequest,
        params
      );
    },
    handleResetPasswordRequest(status, data) {
      if (status == 200) {
        this.oldPass = "";
        this.newPass = "";
        this.confirmNewPass = "";

        this.$vs.notify({
          title: this.$t("Successful"),
          text: this.$t("Profile_Message_5"),
          color: "success",
        });
      }

      if (status == 400) {
        if (data.data.old_password != null) {
          this.errorMessage = data.data.old_password[0];
          this.oldPassValidate = true;
        } else if (data.data.new_password1 != null) {
          this.errorMessage = data.data.new_password1[0];
          this.newPassValidate = true;
        } else if (data.data.new_password2 != null) {
          this.errorMessage = data.data.new_password2[0];
          this.confirmPassValidate = true;
        } else if (data.data.non_field_errors != null) {
          this.errorMessage = data.data.non_field_errors[0];
          this.confirmPassValidate = true;
        }
      }
      this.$vs.loading.close();
    },
    resetPasswordRequest() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      var params = {
        old_password: this.oldPass,
        new_password1: this.newPass,
        new_password2: this.confirmNewPass,
      };

      Api.put(
        API.BASEURL + API.CHANGEPASS,
        this.handleResetPasswordRequest,
        params
      );
    },
  },
  computed: {
    getCurrentLocaleData() {
      const locale = this.$i18n.locale;
      if (locale === "en") return { flag: "us", lang: "English" };
      else if (locale === "tr") return { flag: "tr", lang: "Türkçe" };
    },
    i18n_locale_img() {
      const locale = this.locale;
      if (locale === "en") return require("@/assets/images/flags/en.png");
      else if (locale === "tr") return require("@/assets/images/flags/tr.png");
      else return require("@/assets/images/flags/en.png");
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },
    getEmail() {
      return this.$store.state.email;
    },
    getCompany() {
      return this.$store.state.company;
    },
    getUserName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getDesignMarketPermission() {
      return this.$store.state.designMarketPermission;
    },
    getDentalPermission() {
      return this.$store.state.dentalPlatformPermission;
    },
    getDesignMarketCredit() {
      return this.$store.state.designMarketCredit;
    },
    getDentalCredit() {
      return this.$store.state.dentalCredit;
    },
    getLocale() {
      return this.$store.getters.getLocale;
    },
  },
  watch: {
    getLocale(newValue, oldValue) {
      this.setLocaleLang();
    },
  },
  components: {
    vSelect,
  },
  created() {
    this.setLocaleLang();
    this.getCompanyInfo();
    this.userName = this.getUserName;
    if (this.$route.query.active_tab != null) {
      this.activeTab = parseInt(this.$route.query.active_tab);
    }
  },
};
</script>

<style lang="scss">
//input number'da arttır azalt butonlarını saklama
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<style lang="scss" scoped>
.img-container {
  width: 12rem;
}
#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}
</style>
